import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
} from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { methodGetTrade, methodInitPay } from "../../../api/apipay/api";
import { InitPayRsp } from "../../../api/apipay/pay";
import { methodGetCode } from "../../../api/apiperm/api";
import { Code } from "../../../model/permission/code";
import { Trade, TradeStatus, VIPTime } from "../../../model/security/Pay";
import { TradeKind } from "../../../model/security/TradeKind";
import { User } from "../../../model/UserData";
import { actionSetPayDialog, actionSetRightsDialog } from "../../../store/dialog/reducer";
import { selectPayDialog } from "../../../store/dialog/selector";
import { useAppDispatch } from "../../../store/hooks";
import { selectUser } from "../../../store/user/selector";
import { Colors } from "../../../tools/color";
import { sleep, useInterval } from "../../../tools/time";
import { RefreshPage } from "../../../tools/url";
import { AlertSuccess } from "../../basic/feedbacks/snackbar/Snackbar";
import { XStack, YStack } from "../../basic/surfaces/box/XStack";
import { SwitchContent } from "../../basic/surfaces/switch_content";
import flashPayImg from "./asset/flash_pay.jpg";
import svipPayImg from "./asset/svip_pay.jpg";
import vipPayImg from "./asset/vip_pay.jpg";

export function VIPPayDialog() {
    const dispatch = useAppDispatch();

    const param = useSelector(selectPayDialog);

    const onClose = () => {
        dispatch(actionSetPayDialog({ show: false, kind: 0 }));
    };

    return (
        <Dialog open={param.show} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                扫码支付开通&nbsp;
                {param.kind === TradeKind.VIP && <>BB大会员</>}
                {param.kind === TradeKind.SVIP && <>BB超级大会员</>}
            </DialogTitle>

            <DialogContent>
                <SwitchContent
                    titles={["7天", "1月", "2月", "3月"]}
                    contents={[
                        <VIPPay kind={param.kind} vipTime={VIPTime.Day7} />,
                        <VIPPay kind={param.kind} vipTime={VIPTime.Month1} />,
                        <VIPPay kind={param.kind} vipTime={VIPTime.Month2} />,
                        <VIPPay kind={param.kind} vipTime={VIPTime.Month3} />,
                    ]}
                    contentMargin="0 0 0 0"
                    defaultIndex={1}
                    marginBottom="0"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>取消</Button>
            </DialogActions>
        </Dialog>
    );
}

interface VIPPayProps {
    kind: TradeKind;
    vipTime: VIPTime;
}

function VIPPay(props: VIPPayProps) {
    const { kind, vipTime } = props;
    return (
        <Pay
            kind={kind}
            param={vipTime.toString()}
            onSuccess={() => {
                sleep(1000).then(() => {
                    RefreshPage();
                });
            }}
            successMsg="支付成功，即将自动刷新网页（若网页长时间没有刷新，您可以手动刷新）"
        />
    );
}

interface PayProps {
    kind: TradeKind;
    param: string;
    onSuccess: (trade: Trade, user: User) => void;
    successMsg: string;
    hintDiv?: any;
}
export function Pay(props: PayProps) {
    const dispatch = useAppDispatch();

    const { kind, param, successMsg, onSuccess, hintDiv } = props;

    const [rsp, setRsp] = useState(new InitPayRsp());
    const [status, setStatus] = useState(TradeStatus.None);

    const user = useSelector(selectUser);

    const callInitPay = useCallback(
        async (code: string) => {
            return methodInitPay(dispatch, {
                user_id: user.id,
                kind,
                param,
                code: code,
            }).then((rsp) => {
                setRsp(rsp);
                setStatus(rsp.trade.status);
            });
        },
        [dispatch, kind, user, param]
    );

    useEffect(() => {
        if (rsp.trade.user_id === user.id && rsp.trade.kind === kind && rsp.trade.param === param) {
            return;
        }
        if (kind === TradeKind.None) {
            return;
        }
        setStatus(TradeStatus.None);
        callInitPay("");
    }, [dispatch, callInitPay, rsp, kind, param, user]);

    useInterval(() => {
        if (rsp.trade.id.length === 0) {
            return;
        }
        if (
            status !== TradeStatus.None &&
            status !== TradeStatus.PayInit &&
            status !== TradeStatus.PayCallbackReceived
        ) {
            return;
        }
        methodGetTrade(dispatch, {
            id: rsp.trade.id,
        }).then((rsp) => {
            const trade = rsp.trade;
            setStatus(trade.status);
            if (trade.status === TradeStatus.ApplySuccess) {
                if (onSuccess) {
                    onSuccess(rsp.trade, rsp.user);
                }
            }
        });
    }, 3000);

    const makeContent = () => {
        if (status === TradeStatus.None) {
            return <InitPayDisplay />;
        }
        if (status === TradeStatus.PayInit) {
            return (
                <WaitPayDisplay
                    kind={kind}
                    rsp={rsp}
                    hintDiv={hintDiv}
                    refreshInitPay={callInitPay}
                />
            );
        }
        if (status === TradeStatus.PayCallbackReceived) {
            return <SuccessDisplay msg="已收到支付，处理中请稍候..." />;
        }
        if (status === TradeStatus.ApplySuccess) {
            return <SuccessDisplay msg={successMsg} />;
        }
        if (status === TradeStatus.Timeout) {
            return <ErrorDisplay msg="订单已超时" hint="请关闭窗口重试" />;
        }
        if (status === TradeStatus.Closed) {
            return (
                <ErrorDisplay msg="已有新的订单, 当前订单已关闭" hint="请使用新打开的订单支付" />
            );
        }

        return <ErrorDisplay msg="订单状态异常" />;
    };

    return <>{makeContent()}</>;
}

function InitPayDisplay() {
    return (
        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
            <p>内容加载中...</p>
        </Stack>
    );
}

interface WaitPayDisplayProps {
    kind: TradeKind;
    rsp: InitPayRsp;
    hintDiv?: any;
    refreshInitPay: (code: string) => Promise<void>;
}
function WaitPayDisplay(props: WaitPayDisplayProps) {
    const { kind, rsp, hintDiv, refreshInitPay } = props;

    const [refreshing, setRefreshing] = useState(false);
    const [code, _setCode] = useState("");
    const setCode = (s: string) => {
        if (code !== s) {
            setRefreshing(true);
            refreshInitPay(s).then(() => {
                setRefreshing(false);
            });
        }
        _setCode(s);
    };
    const [reduceInfo, setReduceInfo] = useState("");
    return (
        <Stack sx={{ alignItems: "center" }}>
            <Box sx={{ position: "relative" }}>
                <BackgroundImage kind={kind} price={rsp.price} reduceInfo={reduceInfo}>
                    <QRDisplay rsp={rsp} refreshing={refreshing} />
                </BackgroundImage>
            </Box>
            <CodeInput kind={kind} setCode={setCode} setReduceInfo={setReduceInfo} />
            {hintDiv}
        </Stack>
    );
}

function QRDisplay(props: { rsp: InitPayRsp; refreshing: boolean }) {
    const { rsp, refreshing } = props;
    const getKind = () => {
        if (refreshing) {
            return 0;
        }
        if (rsp.qrcode_text.length > 0) {
            return 1;
        }
        if (rsp.qrcode_url.length > 0) {
            return 2;
        }
    };

    const kind = getKind();
    return (
        <>
            {kind === 0 && (
                <Box
                    sx={{
                        width: "270px",
                        height: "270px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                    <Box sx={{ mt: "30px" }}>支付二维码加载中，请稍后</Box>
                    <Box sx={{ fontSize: 10 }}>若长时间（30s）未显示，可关闭窗口重试</Box>
                </Box>
            )}
            {kind === 1 && (
                <Box sx={{ position: "relative", width: "270px", height: "270px" }}>
                    <QRCodeSVG size={270} value={rsp.qrcode_text} />
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                            color: "rgb(0,0,255)",
                            fontWeight: 700,
                            fontSize: 25,
                            backgroundColor: "white",
                            border: "solid 1px",
                        }}
                    >
                        调试
                    </Box>
                </Box>
            )}
            {kind === 2 && (
                <Box sx={{ position: "relative" }}>
                    <img src={rsp.qrcode_url} alt="微信付款二维码" width="270px" height="270px" />
                </Box>
            )}
        </>
    );
}

interface CodeInputProps {
    setCode: (code: string) => void;
    setReduceInfo: (s: string) => void;
    kind: TradeKind;
}
function CodeInput(props: CodeInputProps) {
    const { setCode: setCodeID, setReduceInfo, kind } = props;
    const dispatch = useAppDispatch();
    const [input, setInput] = useState("");
    const [code, setCode] = useState<Code | undefined>(undefined);
    const handleOnClick = () => {
        if (input.length === 0) {
            AlertSuccess("请输入折扣码");
            return;
        }
        methodGetCode(dispatch, { code: input }).then((rsp) => {
            const c = rsp.code;
            setCode(c);
            if (c.valid) {
                setCodeID(c.code);
                setReduceInfo(`使用${c.name}`);
            } else {
                setCodeID("");
                setReduceInfo("折扣码不可用");
            }
        });
    };
    const getOffset = () => {
        if (kind === TradeKind.FlashCoin) {
            return "-50px";
        }
        return "20px";
    };
    return (
        <YStack sx={{ mt: getOffset() }}>
            <XStack>
                <TextField
                    sx={{ ml: "30px", minWidth: "230px" }}
                    size="small"
                    placeholder="折扣码"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    helperText="多次提交无效的折扣码会导致账户被封"
                />
                <Button sx={{ ml: "20px", mb: "24px" }} onClick={handleOnClick}>
                    应用
                </Button>
            </XStack>
            {code !== undefined && (
                <Box sx={{ mt: "10px" }}>
                    {code.name}&nbsp;{code.info}
                </Box>
            )}
        </YStack>
    );
}

function SuccessDisplay(props: { msg: string }) {
    const { msg } = props;
    return (
        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
            <Box
                sx={{
                    width: "300px",
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CheckCircleIcon sx={{ color: Colors["success"], fontSize: 60 }} />
            </Box>
            <p>{msg}</p>
        </Stack>
    );
}

function ErrorDisplay(props: { msg: string; hint?: string }) {
    const { msg, hint } = props;
    return (
        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
            <Box
                sx={{
                    width: "300px",
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CancelIcon sx={{ color: Colors["error"], fontSize: 60 }} />
            </Box>
            <p>{msg}</p>
            <p>{hint !== undefined ? hint : "请联系微信客服（24小时内响应）：BBGRE01"}</p>
        </Stack>
    );
}

function BackgroundImage(
    props: PropsWithChildren<{ kind: TradeKind; price: number; reduceInfo: string }>
) {
    const { children, kind, price, reduceInfo } = props;

    const dispatch = useAppDispatch();

    const getRelative = () => {
        if (kind === TradeKind.VIP) {
            return ["377px", "220px", vipPayImg, "大会员底图", "240px"];
        }
        if (kind === TradeKind.SVIP) {
            return ["377px", "225px", svipPayImg, "超级大会员底图", "240px"];
        }
        if (kind === TradeKind.FlashCoin) {
            return ["323px", "215px", flashPayImg, "闪卡支付底图", "240px"];
        }
        return ["377px", "220px", "", "底图", "310px"];
    };
    const [top, left, imgSrc, alt, priceTop] = getRelative();
    return (
        <Box sx={{ position: "relative" }}>
            {/* 背景 */}
            <img src={imgSrc} alt={alt} width="700px" />
            {/* 价格文字 */}
            {price > 0 && (
                <>
                    <Box
                        sx={{
                            position: "absolute",
                            top: priceTop,
                            left: "50%",
                            transform: "translateX(-50%)",
                        }}
                    >
                        <YStack>
                            {(kind === TradeKind.VIP || kind === TradeKind.SVIP) && (
                                <>
                                    <XStack>
                                        支付前请仔细阅读
                                        <Button
                                            variant="text"
                                            sx={{ fontSize: 16 }}
                                            onClick={() => {
                                                dispatch(actionSetRightsDialog(true));
                                            }}
                                        >
                                            会员权益表
                                        </Button>
                                    </XStack>
                                    <Box sx={{ mt: "-8px" }}>一经开通不退不换</Box>
                                </>
                            )}
                            <Box sx={{ fontSize: 20, mt: "8px" }}>
                                {reduceInfo.length > 0 ? reduceInfo + "，" : ""}总计&nbsp;
                                <b>{price}</b>
                                &nbsp;元，请使用<b>微信</b>支付
                            </Box>
                        </YStack>
                    </Box>
                </>
            )}
            {/* 支付二维码 */}
            <Box sx={{ position: "absolute", top: top, left: left, zIndex: 10 }}>{children}</Box>
            <Box
                sx={{
                    position: "absolute",
                    top: `calc(${top} + 290px)`,
                    left: left,
                    zIndex: 12,
                    fontSize: 13,
                    // color: Colors["bbgray"],
                    textAlign: "center",
                }}
            >
                支付完成后请在本页面等待，网页会自动刷新
            </Box>
        </Box>
    );
}
